<template>
  <div class="setPasswordContainer">
    <img
      :src="mainLogo"
      class="setPasswordImage" >
    <div class="setPasswordTitle">{{ companyName }} icon portal Password Set Screen</div>
    <hr >
    <Spinner v-show="loading" />
    <div
      v-show="!loading"
    >
      <div
        v-show="!tokenGood && tokenType==''"
        class="setPasswordText">
        The token provided in the link is not valid. Please use the link provided in the email sent to the account email address.
      </div>
      <div
        v-show="tokenExpired && tokenType=='reset'"
        class="setPasswordText">
        The token provided for <b>{{ tokenUsername }}</b> has expired. Please visit the portal address below and perform a new forgotten password request.
      </div>
      <div
        v-show="tokenExpired && tokenType=='create'"
        class="setPasswordText">
        The token provided for <b>{{ tokenUsername }}</b> has expired. Please ask your administrator to set your password for you, or use the forgotten password feature on the icon portal login page.
      </div>
      <div
        v-show="!tokenExpired && tokenGood && !userExist"
        class="setPasswordText">
        The user making this request has been deleted from the system. Please contact your administrator.
      </div>
      <div
        v-show="(!tokenGood || tokenExpired) && tokenUsername===''"
        class="setPasswordText">
        To log in to the portal directly, please use:&nbsp;
        <a
          :href="linkUrl"
          title="icon portal"
        >
          {{ linkUrl }}
        </a>.
      </div>
      <div
        v-show="tokenGood"
        class="setPasswordArea">
        <div class="setPasswordHeading">Set Password</div>
        <div>You are viewing this screen because you have received an email sent to <b>{{ tokenUsername }}</b>.</div>
        <div>Please enter your new password below and press the change password button to log into the ICON portal.</div>
        <br><br>
        <label
          for="password"
          class="passwordText"
        >Password</label>
        <input
          id="password"
          v-model="password"
          type="Password">
        <br><br>
        <label
          for="confirmPassword"
          class="passwordText"
        >Confirm Password</label>
        <input
          id="confirmPassword"
          v-model="confirmPassword"
          type="Password"
          @keydown="checkSubmission">
        <br><br>
        <div
          v-show="errorText != ''"
          class="setPasswordError"
        >
          {{ errorText }}
        </div>
        <div
          v-show="errorText == ''"
          class="setPasswordError"
        >
          &nbsp;
        </div>
        <br><br>
        <button
          title="Apply the password change"
          class="changePasswordButton"
          @click="changePassword"
        >Change Password</button>
      </div>
    </div>
  </div>
</template>

<script>
import { setPasswordMixin } from '../mixins/setPassword'
import Spinner from '../components/common/spinner'
import AuthRepository from '../communications/repositories/authRepository'
import { usersMixin } from '../mixins/users'
export default {
  metaInfo: {
    meta: [
      { name: 'description', content: `icon portal Set Password` }
    ],
    title: 'icon portal - Set Password'
  },
  components: {
    Spinner
  },
  mixins: [
    setPasswordMixin,
    AuthRepository,
    usersMixin
  ],
  data () {
    return {
      loading: true,
      tokenGood: false,
      tokenExpired: false,
      tokenType: '',
      tokenUsername: '',
      password: '',
      confirmPassword: '',
      userExist: false,
      errorText: ''
    }
  },
  computed: {
    mainLogo () {
      if (this.$store.getters.channelCompany.imageRoot === undefined) {
        return '/logo.png'
      } else {
        return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.siteLogo}`
      }
    },
    companyName () {
      return this.$store.getters.channelCompany.name
    },
    linkUrl () {
      return window.location.origin + '/'
    },
    token () {
      if (this.$route.query.token) {
        return this.$route.query.token
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.decodeToken()
  },
  methods: {
    decodeToken: async function () {
      if (this.token.length > 0) {
        var rtn = await this.decryptEmailToken(this.token)
        if (rtn.indexOf('|') > 0) {
          var vals = rtn.split('|')
          if (vals.length === 3) {
            this.tokenUsername = vals[0]
            this.tokenType = vals[1]
            var expires = vals[2]
            if (new Date(expires) < Date.now()) {
              this.tokenExpired = true
              this.tokenGood = false
            } else {
              this.tokenExpired = false
              this.tokenGood = true
              const rtnUserExist = await this.getUserByNameWithoutAuth(this.tokenUsername)
              if (rtnUserExist?.id > 0) {
                this.userExist = true
              } else {
                this.userExist = false
                this.tokenGood = false
              }
            }
          } else {
            this.tokenGood = false
          }
        } else {
          this.tokenGood = false
        }
      } else {
        this.tokenGood = false
      }
      this.loading = false
    },
    changePassword: async function () {
      this.errorText = ''
      if (this.password !== this.confirmPassword) {
        this.errorText = 'The passwords entered do not match. Please ensure that they do before changing the password.'
      } else if (this.password.length < 8 || this.password.length > 255) {
        this.errorText = 'The password entered must contain between 8 and 255 characters.'
      } else {
        await this.authRequestSendSetPassword(this.tokenUsername, this.password, this.token)
      }
    },
    checkSubmission (key) {
      if (key.keyCode === 13) {
        this.changePassword()
      }
    }
  }
}
</script>
<style scoped>
  .setPasswordContainer {width: 100%;padding: 20px 40px;}
  .setPasswordImage {margin: 20px auto; display: inline-block;}
  .setPasswordTitle {font-size: 2em; font-weight: bold; display: inline-block; vertical-align: top; margin: 30px;}
  .setPasswordText {margin: 20px 0 20px 20px; width: 100%;}
  .setPasswordArea {width: 60%;padding: 40px;border:black 1px solid; margin: 30px auto; min-width:300px;}
  .setPasswordHeading {font-size: 1.6em; font-weight: bold; margin-bottom: 10px;}
  .passwordText {width: 200px; display: inline-block;}
  .changePasswordButton {float:right;}
  .setPasswordError {color: darkred;}
</style>
